module.exports = {
  siteUrl: 'https://www.alsophro.com',
  sitemapPath: '/sitemap.xml',
  title: 'Sophrologie à Voiron (Isère, 38)',
  description: "Avec la sophrologie, redécouvrez et renforcez vos capacités de bien-être, d'adaptation et d'évolution.  Basée en Isère (38) à Voiron près de Grenoble et Voreppe, je vous accompagne dans la découverte et la pratique d'exercices de sophrologie en séances individuelles ou en groupe.",
  author: '@oliviergenevest',
  locales: {fr:
    {
      path: 'fr',
      name: 'Français',
      default: true
    },
/*    en:
    {
      path: 'en',
      name: 'English'
    }*/
  },

};
