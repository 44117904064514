/* TODO : ajouter composant SEO avec les valeurs du site global */

import React from 'react';
import Link from '../components/ExtendedLink';
import {IntlProvider} from 'react-intl'
// Messages
import en from '../translations/en.json';
import fr from '../translations/fr.json';
import Headroom from 'react-headroom';
import styled from 'styled-components';
import Header from '../components/header';
import Footer from '../components/footer';

import Reboot from '../style/reboot';
import Global from '../style/global';
/*import ModalContext from '../store/modalContext';
import Modals from '../modals';*/
/*import CookieConsent from 'react-cookie-consent';*/
import { FormattedMessage} from 'react-intl';
import { layoutTypes } from '../types/propTypes';

import {Text} from '../components/Elements'
const messages = { en, fr };


const SiteWrapper = styled.div`
  background: white;
`;

const Layout = ({ children, pageContext, location }) => {



  return (
    <IntlProvider locale={pageContext.locale} key={pageContext.locale}  messages={messages[pageContext.locale]}  defaultLocale="fr" >
      <SiteWrapper id="top">
        <Reboot />
        <Global />
             
   
        
        {children}
     
       

      </SiteWrapper>
    </IntlProvider>
   
  );
};

Layout.propTypes = layoutTypes;

export default Layout;
