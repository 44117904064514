import React from "react"
import ReactDOM from 'react-dom';
import styled from 'styled-components'
import { useSpring, useTransition, animated , config} from "react-spring"
import ScrollLock , { TouchScrollable } from 'react-scrolllock'
/*import theme from '../../config/theme'*/
import { MdClose } from 'react-icons/md'
import { colors, mq } from "../consts/style"

// Use a ternary operator to make sure that the document object is defined
const domTarget = typeof document !== `undefined` ? document.body : null;


const Popup = ({ isActive, handleClose, children }) => {

  /* useEffect(() => {
      if (typeof document !== `undefined`) {
        const domTarget = typeof document !== `undefined` ? document.body : null;
        return
      }
    }, [])*/

  const animOverlay = useSpring({
    from: { opacity: 0},
    to: { opacity: isActive ? 1 : 0},
    config: config.gentle 
  });


  const transitions = useTransition(isActive,  {
    initial: {  transform: 'translateY(-50px)',opacity: 1},
    from: { transform: `translateY(-50px)`, opacity: 0 },
    enter: { transform: `translateY(0px)`, opacity: 1},
    leave: { transform: `translateY(-20px)`, opacity: 0},
    unique: true,native:true,config: config.gentle 
  });
   





 

  return (
    domTarget && ReactDOM.createPortal(
    <> <ScrollLock isActive={isActive} />
             <TouchScrollable>
 
      <Wrapper style={{  zIndex: isActive ? '10':'-1', background: isActive ? '#141313d4':'transparent'}}>
        <Overlay style={animOverlay}  onClick={() => handleClose(false)} />
        {transitions(          ( props, item, key ) =>
            item && (
              <Content key={key}  style={props} >
                 <PButtonClose  onClick={() => handleClose(false)} >
                    <MdClose style={{ verticalAlign: 'middle', fontSize:'3.2rem', fontWeight:'800' }} />
                </PButtonClose>
                {children}
              </Content>
            )
        )}
      </Wrapper>
              </TouchScrollable>
 
    </>, domTarget )

    )
  
}

export default Popup

const Wrapper = styled(animated.div)`
  position: fixed;
  bottom:0;
  right:0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top:3.6rem;
  justify-content: center;
  overflow-y: auto;
  background:#141313d4;
  ${mq.tablet `  
     padding-top:0;
   ` }
`;

const Content = styled(animated.div)`
  position: relative;
  margin:1.6rem;
  
  width:570px;
  background-color:white;
  border-radius:.8rem;
`;

const PButtonClose = styled.button`
  position:absolute;
  border:0;
  right:2rem;
  top:1rem;
  background-color:${colors.text2};
  color: ${colors.backgroundGreenLight};
  cursor:pointer;
  border-radius: 100%;
  padding: 4px;
  transition:.3s ease-in-out;
    &:hover, &:focus {
        color:white;
      background-color:${colors.text1};
      
    }
`

const Overlay = styled(animated.div)`
 /* position: absolute;
  top: 0;
  left: 0;
  bottom:0;
  right:0;
  width: 100%;

    overflow-y: auto;
  background:#28283ca1;
  background:#F6F6F6;
  background:#141313d4;*/
`;
