import React, {  useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { FormattedMessage} from 'react-intl'
import Link from './ExtendedLink'
import { colors, font,  boxShadow, mq , space} from '../consts/style'
import  BtnPrimary from './buttons/ButtonRounded'
import  { LanguageSwitcher }  from './language-switcher'
import {  Flex } from './Elements'
import { headerTypes } from '../types/propTypes'
import { MenuAltRight } from '@styled-icons/boxicons-regular'
import { XCircle } from '@styled-icons/bootstrap'
import { useSpring, animated } from 'react-spring'
import instagramIcon from '@iconify/icons-brandico/instagram'
import { Icon } from '@iconify/react'
import facebookRect from '@iconify/icons-brandico/facebook-rect'
import LogoSVG from './LogoSVG'
import Boop from './boop'
import { StaticImage } from "gatsby-plugin-image"
import Popup from './popup'
import ContactPopUp from './contactPopUp'
import { useModalWithData } from '../hooks/modal-hook'


const IconLink = ({to, icon, text}) => {
  return(

    <LinkSocial title={text} href={to} target="_blank"  rel="nofollow noopener noreferrer">
      {icon}
    </LinkSocial>
  )
}



const LinkSocial = styled.a`

`

const Wrapper = styled.div`
background-color:white;
  ${boxShadow};
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  ${mq.tablet`
     padding: 0 2rem;
    `}
`;



const LanguageSwitcherWrap = styled.div`
  position:absolute;
  right: 0;
  padding: 0 2rem;
  background-color: white;
  height: 100%;
  ${mq.tablet`
    display:none;
   `}

`;

const HeaderInner = styled.div`
  display: flex;
  width: 124rem;
  justify-content: space-between;
  align-items: center;
  padding:  1rem 0;
  
`;



const MainMenu = styled.ul`
  display: none;
  
${mq.tablet_up`
  display: flex;
  list-style: none;
  justify-self: flex-start;
  margin: 0;
  padding:0;
  align-items:center;
 `}

 
`;

const SubMenu = styled.ul`
  border-radius:4px;
  background:${colors.backgroundGreenLight};
  transform:translateY(10px);
  opacity:0;
  transition:0.3s all ease-in-out; 
  visibility:hidden;
  flex-wrap:wrap;
  list-style:none;
  margin-top:6rem;
  top:0;
  padding:2rem;
  left:0;
  position:absolute;
  & li {
    padding:1rem;
    padding-left:0;
    width:100%;
    min-width:250px;
  }
`

const MenuItem = styled.li`
  position: relative;
  z-index:2; 
  color: ${colors.dark};
  cursor:pointer;
  &:hover {
    color: ${colors.dark};
    ${SubMenu} {
    
      display:block;
      visibility:visible;
      position:absolute;
      opacity:1;
      transform:translateY(0);
      transition:0.3s all ease-in-out;
    }
  }
  margin-right:2.5rem;
  padding:2rem 0;
     
`;

const LinkHeader = styled(props => <Link {...props} />)`
  
  color: ${colors.dark};
  &:hover {
    color: ${colors.dark};
  }

    position:relative;
    padding-bottom:${space.half};
    text-decoration:none;
     &:before,
      &:after {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 0; right: 0;
          height: 2px;
          background-color: ${colors.background};
      }
      &:before {
          opacity: 0;
          transform: translateY(-${space.double});
          transition: transform 0s cubic-bezier(.175,.885,.32,1.275), opacity 0s;
      }
      &:after {
          opacity: 0;
          transform: translateY(calc(${space.double}/2));
          transition: transform .3s cubic-bezier(.175,.885,.32,1.275), opacity .3s;
      }
      &:hover,
      &:focus {
        text-decoration:none;
          &:before,
          &:after {
              opacity: 1;
              transform: translateY(0);
          }
          &:before {
              transition: transform .3s cubic-bezier(.175,.885,.32,1.275), opacity .3s;
          }
          &:after {
              transition: transform 0s 0.3s cubic-bezier(.175,.885,.32,1.275), opacity 0s .3s;
          }
      }
`;





const LogoLink = styled(LinkHeader)`
  
  height:100%;
 
   ${mq.mobile`
      img { 
        height:5rem;
        width: auto!important;
      }
    `}

  &:hover:before, &:hover:after,  &.active:before , &.active:after ,&:focus:before,  &:focus:after
  {
    content:none;
  }
`;

const BurgerMenu = styled.button`
  position:relative;
  border:0;
  margin-right:1rem;
  display:block;
  color:${colors.green};
   background-color:transparent;
  &:focus {
    outline:none;
  }
  ${mq.tablet_up` 
   display:none;
 `}
   ${mq.tablet`
     margin-right:0;
         padding: 0;
    `}
`;

const CloseMenuMobile = styled.button`
  position:absolute;
  top:3rem;
  right:2rem;
  display:block;
  border:none;
 & svg { fill:${colors.text2};background:transparent!important;}
  background:transparent;
  &:focus {
    outline:none;
  }
`;

/*MENU MOBILE*/
const StyledMenuAltRight = styled(MenuAltRight)`
  color:${colors.text2};
  
`
const StyledXCircle = styled(XCircle)`
  color:${colors.dark};

`

const MenuMobile = styled(animated.div)`
  background-color:white;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  bottom:0;
  right: 0;
  z-index:10;
  transform: translate3d(0,-100%,0);
`
const ContentMobile = styled(animated.div)`

  height:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  nav {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
`

const ItemMobileNav = styled(animated.div)`
  font-size:3rem;
  padding:.5rem;
  margin-bottom:1.5rem;

  
`

const FlexBtnWrapper = styled(Flex)`
  margin-top:2rem;  
  margin-bottom:3rem;
  align-items:flex-start;
  justify-content:center;
  & > * {
    margin:0 ${space.quarter};
  }
  ${mq.mobile`
      margin-top:1rem;
  margin-bottom:1rem;
      `}
`



export default function Header({ location }) {
 
  const [mobileNavOpen, setMobileNavOpen] = useState(false) // mobile menu closed by default
  const MenuAnimation = useSpring({
  native: true,
  to: { opacity: mobileNavOpen ? 1 : 0, transform: mobileNavOpen ? 'translate3d(0,0,0)' : 'translate3d(0,-100%, 0)', backgroundColor: mobileNavOpen ? colors.yellow : '#fff'},
  })

  const {
    modalOpen,
    selected,
    setSelected,
    setModalState
    } = useModalWithData();


    const navItems = useStaticQuery(graphql`
    query navQuery {
     items: allDatoCmsMenuDeNavigation(
        filter: {locale: {eq: "fr"}, root: {ne: true}}
        sort: {order: ASC, fields: position}
      ) {
        nodes {
          
            position
            menuLink {
              ... on DatoCmsService {
                id
                titre
                slug
              }
            }
            root
          
          
        }
      }
    }
  `);
  //console.log(navItems.items.nodes)
  return (
    <> 
   
    <Wrapper>
      <HeaderInner>
        <LogoLink to="/" title="Retour à l'accueil" >
            
            <StaticImage 
              src="../images/logo-al-sophro.png" 
              alt="Aurélie Lasherme, sophrologue à Voiron (Isère)"
              layout="constrained"
              width={226}
            />
        </LogoLink>
       
        <MainMenu>
         
        
          <MenuItem>Particuliers
         
            <SubMenu>
            {navItems.items.nodes.map((navItem, key) => (
              <li key={key}><LinkHeader to={"/sophrologie-pour-particuliers/" + navItem.menuLink.slug} activeClassName="active">{navItem.menuLink.titre}</LinkHeader></li>

                  ))}
            </SubMenu>
            
            
          </MenuItem>
          <MenuItem>
            <LinkHeader to="/interventions-en-entreprises" activeClassName="active"><FormattedMessage id="menu__entreprises"/></LinkHeader>
          </MenuItem>
           <MenuItem>
            <LinkHeader to="/biographie" activeClassName="active"><FormattedMessage id="menu__bio"/></LinkHeader>
          </MenuItem>
           <MenuItem>
            <LinkHeader to="/blog" activeClassName="active"><FormattedMessage id="menu__blog"/></LinkHeader>
          </MenuItem>
          <MenuItem>
            <LinkHeader to="/contact" activeClassName="active"><FormattedMessage id="menu__contact"/></LinkHeader>
          </MenuItem>
          <MenuItem>
            <IconLink to="https://www.facebook.com/ALSphrlg" icon={<Icon title="Facebook" icon={facebookRect} style={{ fontSize: '20px'}} />}  text="Facebook"/>     
          </MenuItem>
          <MenuItem> 
            <IconLink to="https://www.instagram.com/alsophro" icon={<Icon title="Instagram" icon={instagramIcon} style={{fontSize: '20px'}} />} text="Instagram"/>
          </MenuItem>
          <MenuItem>
              <Popup isActive={modalOpen} handleClose={()=>setModalState(false)}>
                <ContactPopUp data={selected} />
              </Popup>
              <BtnPrimary popup onClick={()=>{
                setSelected({selected})
                setModalState(true)
                }}>
                Prendre rendez-vous
              </BtnPrimary>
          </MenuItem>
        </MainMenu>
       
        
        <BurgerMenu  onClick={() => {setMobileNavOpen(true)}}
    aria-label="Navigation"><StyledMenuAltRight title="Navigation" size="48"/></BurgerMenu>
      </HeaderInner>
    </Wrapper>

    <MenuMobile style={ MenuAnimation}> 
        <ContentMobile>
        {/*<StaticImage 
              src="../images/logo-al-sophro.png" 
              alt="Aurélie Lasherme, sophrologue à Voiron (Isère)"
            />*/}
          <nav>
            <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}}>
              <LinkHeader to="/" ><FormattedMessage id="homepage"/></LinkHeader>
            </ItemMobileNav>
            <ItemMobileNav >
              <LinkHeader disabled to="#" activeClassName="active"><FormattedMessage id="menu__particuliers"/></LinkHeader>
            </ItemMobileNav>
            
         
           
            {navItems.items.nodes.map((navItem, key) => (
              <p key={key}><LinkHeader  onClick={() => {setMobileNavOpen(!mobileNavOpen)}} to={"/sophrologie-pour-particuliers/" + navItem.menuLink.slug} activeClassName="active">{navItem.menuLink.titre}</LinkHeader>
 </p>
                  ))}
            
            
            
         
            <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
              <LinkHeader to="/interventions-en-entreprises" activeClassName="active"><FormattedMessage id="menu__entreprises"/></LinkHeader>
            </ItemMobileNav>
            <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
                <LinkHeader to="/biographie" activeClassName="active"><FormattedMessage id="menu__bio"/></LinkHeader>
            </ItemMobileNav>
        
            <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
              <LinkHeader to="/blog" activeClassName="active"><FormattedMessage id="menu__blog"/></LinkHeader>
            </ItemMobileNav>
            <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
                <LinkHeader to="/contact" activeClassName="active"><FormattedMessage id="menu__contact"/></LinkHeader> 
            </ItemMobileNav>
            <ItemMobileNav>
            <Popup isActive={modalOpen} handleClose={()=>setModalState(false)}>
                <ContactPopUp data={selected} />
              </Popup>
              <BtnPrimary popup onClick={()=>{
                setSelected({selected})
                setModalState(true)
                }}>
                Prendre rendez-vous
              </BtnPrimary>
            </ItemMobileNav>
            <ItemMobileNav>
              <IconLink to="https://www.facebook.com/ALSphrlg" icon={<Icon title="Facebook" icon={facebookRect} style={{color: colors.yellow, fontSize: '28px', margin:'10px'}} />}  text="Facebook"/>     
          {"  "}
              <IconLink to="https://www.instagram.com/ALSphrlg" icon={<Icon title="Instagram" icon={instagramIcon} style={{color: colors.yellow, fontSize: '28px', margin:'10px'}} />} text="Instagram"/>
            </ItemMobileNav>
          </nav>
         
          <CloseMenuMobile 
          aria-label="Fermer"
          onClick={() => {setMobileNavOpen(false)}} 
           ><StyledXCircle size="48"  title="Fermer le menu" /></CloseMenuMobile>
        </ContentMobile>    
      </MenuMobile>  
      </>

  );
}

Header.propTypes = headerTypes;