import React from 'react'
import styled from 'styled-components'
import { /*useSpring, useTransition,*/ animated /*, config*/} from "react-spring"
import { colors, mq, font } from "../consts/style"
const PopUpWrapper = styled(animated.section)`
  width:100%;
`
const FlexContent = styled(animated.div)`
  display:flex;
  justify-content:space-between;
  flex-direction:row;
  ${mq.mobile` 
    flex-direction:column;
  `}
`
const Title = styled.h2  `
  font-size:2.4rem;
  margin-bottom:1.6rem;
  margin-right:3.6rem;
`

const ContactPopUp = (props) => {
    return (
    <PopUpWrapper>
      <iframe id="frameRdv"  frameBorder="0" style={{'border':'0','marginBottom':'3.6rem', minHeight:'650px'}} width="100%" height="100%" src="https://www.resalib.fr/agenda/37000"></iframe>
    </PopUpWrapper>
  )
}


export default ContactPopUp