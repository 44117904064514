/*export default {
  desktop: 1280,
  tablet: 1025, 
  tabletSmall: 768,
  mobile: 480,
};*/
const Breakpoints = {
  desktop: 1280,
  tablet: 1025, 
  tabletSmall: 768,
  mobile: 480,
};
export default Breakpoints