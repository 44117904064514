/* eslint-disable quotes */
import bp from './breakpoints';
import { css } from 'styled-components';

export const colors = {

  light: '#fff',
 
  greyLight: '#F6F6F6',
  buttonHover: '#6d6967',
  cta:'#57877A',

  blue:'#7C9E9E', // bleu discordeon
  blueLight:'#F0FBF6', // bleu discordeon light utilisé pour fond 
  orange:'#FF8563', //orangle fluo discordeon
  yellow:'#405E5E', // vert fluo discordeon
  yellowLight:'#F1EFD4',

  
  //alsophro
  greenOld: '#3f5d5d',

  text1:'#7C9E9E',

  dark:'#141313',
  background:'#C2D3DD',
  backgroundGreenLight:'#F0FBF6',
  backgroundGrayLight:'#F6F6F6',
  grey:'#E8E7E7',

   //alsophro new 2022  
   green:'#CFF09E',
   yellow:'#FADC5B',
   greenDark:'#79BD9A',
   blue:'#0B486B',
   text2:'#405E5E',
};

export const space = {
  'quarter': '.5rem',
  'half': '1rem',
  'default': '2rem',
  '3': '3rem',
  'double': '4rem',
  '5': '5rem',
  '6': '6rem',
  '7': '7rem',
  'big': '8rem',
  '9': '9rem',
  'huge': '10rem',
}
const mQueryPoint = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const boxShadow = `
box-shadow : 0px 3px 6px #00000029;
`
export const font = {
  // haut a droite rappel de navigation utilisé comme H1 pour referencement
  pageTitle : ` 
            font-size:4.8rem;
            line-height:normal;
            font-weight:800;
            
            color: ${colors.text2};
            @media screen and (max-width: ${mQueryPoint.tablet}) {
              font-size: 3.8rem;
            }
            &:before {
              color: ${colors.text2};
              content: " ";
              display: inline-block;
              margin-right: 3rem;
              height: 0.5rem;
              width: 9.3rem;
              background-color:  ${colors.text2};
              transform: translateY(-15px);
              @media screen and (max-width: ${mQueryPoint.tablet}) {
                display: block;
              }
            }
`,
// gros titre centré
  title:  ` 
            font-size:3.4rem;
            line-height:normal;
            font-weight:600;
            @media screen and (max-width: ${mQueryPoint.tablet}) {
              font-size: 3.6rem;
            }
          `,
  h2: `
          font-style: normal;
          font-weight: 700;
          font-size: 2.8rem;
          line-height: 31px;
          color: ${colors.text2};
          `,
// avec trait orange oblique devant
  h2Oblique: `
            font-style: normal;
            font-weight: 500;
            font-size: 2.6rem;
            line-height: 31px;
            color: ${colors.blue};
            &:before {
              content: "";
              display: inline-block;
              position: relative;
              left: -1rem;
              height:6px;
              border-radius:8px;
              bottom: 0.5rem;
              background-color: #F0F3D3;
              border:2px solid ${colors.orange};
              width: 30px;
              transform:rotate(69deg);
            
            }
            `,
  h3 :`
            font-style: normal;
            font-weight: 500;
            font-size: 2.6rem;
            line-height: 31px;
            color: ${colors.dark};
            `,

  subtitle: ` 
            font-size:2rem;
            font-style: normal;
            font-weight: normal;
            line-height: 35px;
            text-align:justify;
            @media screen and (max-width: ${mQueryPoint.tablet}) {
              font-size: 1.8rem;
            }
          `,
  text: ` 
            text-align:justify;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            word-break: break-word;
            @media screen and (max-width: ${mQueryPoint.tablet}) {
              font-size: 1.6rem;
            }

          `,

  navigationItem:`
           
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
           
          `,
   
};


export const z = {
  modalBackground: `z-index: 100000;`,
  modal: `z-index: 200000;`,
};



// Creates up & down media queries for your breakpoints
// *** Usage ***
// import { mq } from "**/style.js"
// export const StyledComponent = styled.div`
// ${media.tablet`
//   display: flex;
// `}
// ${media.mobile_up`
//   display: none;
// `}
//`

export const mq = Object.keys(bp).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  acc[`${label}_up`] = (...args) => css`
    @media screen and (min-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});
