import React from 'react';
import styled from 'styled-components';
import { Download } from '@styled-icons/bootstrap';
import Link from '../ExtendedLink';
import { colors } from '../../consts/style';

 const PrimaryLink = styled(Link)`
    font-family:"Open Sans condensed", sans-serif;
    max-width:350px;
    display:inline-block;
    border-radius:.8rem;
    cursor:pointer;
    background:  ${colors.text2};
    border: none;
    color:  white;
    font-weight:400;
    padding: .5rem 2.5rem;
    margin: 0;
    text-align: center;
    transition: all 0.3s ease-out 0s;
    &:hover {
      box-shadow: ${colors.blueLight} 0px 4px 4px;
      transform: translateY(-5px);
      text-decoration: none;
      color: ${colors.dark};
      background:  ${colors.yellow};
    } 
`;
 const PrimaryLinkPopup = styled.button`
    font-family:"Open Sans condensed", sans-serif;
    max-width:250px;
    display:inline-block;
    border-radius:.8rem;
    cursor:pointer;
    background:  ${colors.text2};
    border: none;
    color:  white;
    font-weight:400;
    padding: .5rem 2.5rem;
    margin: 0;
    text-align: center;
    transition: all 0.3s ease-out 0s;
    &:hover {
      box-shadow: ${colors.blueLight} 0px 4px 4px;
      transform: translateY(-5px);
      text-decoration: none;
      background:  ${colors.text2};
      color:${colors.yellow}!important;
    } 
`;

const PrimaryExternalLink = styled.a`
font-family:"Open Sans condensed", sans-serif;
display:inline-block;
border-radius:50px;
cursor:pointer;
background:  ${colors.blue};
border: none;
color: white;
font-weight:400;
padding: .5rem 2.5rem;
margin: 0;
transition:all .35s ease;
text-align: center;
&:hover {
  text-decoration: none;
  background:  ${colors.blue};
  color:${colors.yellow}!important;
}

`;


const BtnPrimary = ({children,  ...props}) => {
 /*console.log(props)*/
return (
    <>
      
      {props.external ? 
      <PrimaryExternalLink target="blank" rel="nofollow noopener noreferrer" href={props.to} {...props}><Download size="24"/> {children}</PrimaryExternalLink> 
      :  
      props.popup ? <PrimaryLinkPopup {...props}>{children}</PrimaryLinkPopup> : <PrimaryLink {...props}>{children}</PrimaryLink>}
    </>
     
)
 }
export default BtnPrimary;
