import "@fontsource/montserrat";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/300-italic.css";

import "@fontsource/manjari";
import "@fontsource/manjari/100.css";
import "@fontsource/manjari/700.css";

import "@fontsource/open-sans-condensed";
import "@fontsource/open-sans-condensed/300.css";
import "@fontsource/open-sans-condensed/700.css";


import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

html { font-family: 'Manjari', sans-serif; }

/*overflow hidden pour ne pas avoir de marge dans swiper full screen (projet.js)*/
html,
body {
 /* overflow-x: hidden;*/
}

@supports (font-variation-settings: normal) {
  html { 
    font-family: 'Manjari', sans-serif;
   }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration:none;
	&:hover {
		text-decoration:underline;
		color:#0B486B;
	}
}


::selection {
  background: #f0fbf5; /* WebKit/Blink Browsers */
  color:#141313;
}




/* VIDEO RESPONSIVE */
.video-responsive { 
  overflow:hidden; 
  padding-bottom:56.25%; 
  position:relative; 
  height:0;
  margin-bottom:3rem;
  }
  
  .video-responsive iframe {
  left:0; 
  top:0; 
  height:100%;
  width:100%;
  position:absolute;
  }

  .swiper {
  z-index:0!important;
}
`;



export default Global;
