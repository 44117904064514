import React from 'react';
import styled from 'styled-components';
import Link from './ExtendedLink';
import {Link as GLink} from 'gatsby';
import { useIntl } from 'react-intl';
import { colors, mq, space, font } from '../consts/style';
import { Text,  Flex } from './Elements';
import { Icon } from '@iconify/react';
/*import instagramIcon from '@iconify/icons-logos/instagram-icon';*/
import instagramIcon from '@iconify/icons-brandico/instagram';
import facebookRect from '@iconify/icons-brandico/facebook-rect';
import { FormattedMessage} from 'react-intl'
import { StaticImage } from "gatsby-plugin-image"

export const WrapperFooter = styled.div`

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position:relative;
  background-color:${colors.backgroundGrayLight};
  height:34.4rem;
  width:100%;
  margin-top:${space.big};
`;

const FooterInner = styled.div`
 padding: 0 2rem;
 display:block;
 width: 100%;
 text-align:center;

 ${mq.tabletSmall_up`
   text-align:left;
  display: flex;
  width: 120rem;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap:2rem;
  `}
 
 ${mq.tablet_up`
  img {
    display:block;
  }
  `}
  
  ul {
    list-style: circle;
    margin-right:2rem;
    ${mq.tabletSmall`
            margin-right:0;
            list-style: none;
          `}
    padding:0;
      li {
         display:inline-block;
         padding:.5rem;
        ${mq.tabletSmall_up`
            display:block;
             padding:0;
          `}
      a {
        color:${colors.dark};
      }
  }
}

`;




const LinkSocial = styled.a`
  display:flex;
  flex-direction:column;
  gap:1rem;
  align-items:center;
  justify-content:center;
`

const FlexIconeWrapper = styled(Flex)`
  padding-bottom:1rem;
  align-items:center;
  justify-content:flex-start;
  gap:${space.default}; 
  ${mq.tabletSmall`
    justify-content:center;
  `}
`

const FlexFooter = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  /*MOBILE*/
  text-align: center;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction:column;
  ${mq.tabletSmall_up`
   text-align: left;  
   flex-direction:row;
   `}
  
`;

const FlexLogosSophro = styled.div`
  display:flex;
  min-width: 250px;
  justify-content: space-around;
  & a {
    filter: grayscale(100);
    transition: all .6s ease;
    &:hover {
      filter: grayscale(0);
    }
  }
`;


const IconLink = ({to, icon, text}) => {
  return(

    <LinkSocial title={text} href={to} target="_blank"  rel="nofollow noopener noreferrer">
      {icon}
      
    </LinkSocial>
  )
}

const Footer= ({ location }) => {
  const intl = useIntl();
  const today = new Date();
  const year = today.getFullYear();

  return (
    <WrapperFooter>   
      <FooterInner>
        <FlexFooter>
          <div>
          <FlexIconeWrapper>
            <IconLink to="https://www.facebook.com/ALSphrlg" icon={<Icon title="Page Facebook" icon={facebookRect} style={{color: colors.dark, fontSize: '28px'}} />} text="Facebook"/>
            <IconLink to="https://www.instagram.com/alsophro" icon={<Icon  title="Page Instagram" icon={instagramIcon} style={{color: colors.dark, fontSize: '28px'}} />} text="Page Instagram"/>
          </FlexIconeWrapper>
          <ul>
            <li><Link to="/mentions-legales"> <FormattedMessage id="footer__mentions-legales"/></Link> </li>
            <li><Link to="/protection-des-donnees"><FormattedMessage id="footer__data-protection"/></Link> </li>
            <li><Link to="/credits"> <FormattedMessage id="footer__credits"/></Link></li>
          </ul>
          {/* -   <GLink to="/en">EN</GLink> | <GLink to="/">FR</GLink> <br/>*/}
          <span>© alsophro EI - {year} </span>
          </div> 
          <div>
            <p style={{fontSize:"1.2rem"}}>Aurélie Lasherme est membre adhérente de :</p>
            <FlexLogosSophro>
              <a href="https://www.chambre-syndicale-sophrologie.fr/" target="blank" title="Visiter le site du syndicat des sophrologues professionnels" rel="noopener noreferer"
                 className=""> <StaticImage 
                  src="../images/logo-chambre-syndicale-sophrologie.png" 
                  alt="Chambre syndicale de la sophrologie"
                  layout="constrained"
                  width={75}
                />
                </a>
                <a href="https://www.syndicat-sophrologues-professionnels.fr/" target="blank" title="Visiter le site du syndicat des sophrologues professionnels" rel="noopener noreferer"
                 className=""> 
                <StaticImage 
                  src="../images/logo-syndicat-sophrologues-professionnels.png" 
                  alt="Syndicat des sophrologues professionnels"
                  layout="constrained"
                  width={75}
                />
                 </a>
              </FlexLogosSophro>
            </div>
        </FlexFooter>
      </FooterInner>
    </WrapperFooter>
  );
};

export default Footer;
